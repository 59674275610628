<script setup>
import { ref, reactive, defineEmits, onMounted } from 'vue'
// import { formatCurrency, formatNumber, formatDate } from '@/helpers'
import { helpers, required } from '@vuelidate/validators'
import { useToast } from 'primevue/usetoast'

import errorHandler from '@/helpers/errorhandler'
import FxReportContainer from '@/components/FxReportContainer'
import LaporanApotekerService from '@/services/LaporanApotekerService'
import PabrikService from '@/services/PabrikService'
import useVuelidate from '@vuelidate/core'
import { saveAs } from 'file-saver'

const toast = useToast()
const laporanService = new LaporanApotekerService()
const pabrikService = new PabrikService()
let dialogViewer = ref(false)
let pdfSource = ref('')
let pdfTitle = ref('PDF Viewer')
let pdfFilename = ref('')

let isLoadingExport = ref(false)
let isLoading = ref(false)
let form = reactive({
  /*
  periode_from: null,
  periode_to: null,
  */
  triwulan: null,
  pabrik: null,
})
let submitted = ref(false)
let list_pabrik = ref([])
let list_triwulan = ref([])

onMounted(async () => {
  list_triwulan.value = [
    { id: '1', nama: '01 Jan - 31 Mar' },
    { id: '2', nama: '01 Apr - 31 Jun' },
    { id: '3', nama: '01 Jul - 30 Sep' },
    { id: '4', nama: '01 Okt - 31 Des' },
  ]
})

const rules = {
  /*
  periode_from: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
  periode_to: {
    required: helpers.withMessage('Tanggal harus diisi.', required),
  },
  */
  triwulan: {
    required: helpers.withMessage('Periode triwulan harus diisi.', required),
  },
  pabrik: {
    required: helpers.withMessage('Pabrik harus diisi.', required),
  },
}

const v$ = useVuelidate(rules, form)

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['toggle'])

function search(isFormValid) {
  submitted.value = true

  if (!isFormValid) {
    return
  }

  // loadData()
  onExport()
}

function searchPabrik(e) {
  pabrikService
    .get(`?filter[nama]=${e.query}`)
    .then((res) => {
      list_pabrik.value = res.data.data
    })
    .catch((err) => {
      errorHandler(err, 'Data Pabrik', toast)
    })
}

function onExport() {
  isLoadingExport.value = true
  /*
  const startDate = dayjs(form.periode_from).format('YYYY-MM-DD')
  const endDate = dayjs(form.periode_to).format('YYYY-MM-DD')
  */
  const triwulan = form.triwulan
  let pabrikId = 0
  if (form.pabrik) pabrikId = form.pabrik.id
  isLoading.value = true
  laporanService
    /*.exportLaporanTriwulanBatch(startDate, endDate, pabrikId)*/
    .exportLaporanTriwulanBatch(triwulan, pabrikId)
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      pdfSource.value = pdfUrl + '#toolbar=0'
      pdfFilename.value = 'laporan-apoteker-triwulan-batch.pdf'
      pdfTitle.value = 'PDF Viewer (' + pdfFilename.value + ')'
      dialogViewer.value = true
    })

    .catch((err) => {
      errorHandler(err, 'Data Apoteker Triwulanan per Batch', toast)
    })
    .finally(() => ((isLoadingExport.value = false), (isLoading.value = false)))
}

function onPrintPdf() {
  const iframe = document.createElement('iframe')
  iframe.src = pdfSource.value
  iframe.style.display = 'none'
  document.body.appendChild(iframe)

  // Use onload to make pdf preview work on firefox
  iframe.onload = () => {
    iframe.contentWindow.focus()
    iframe.contentWindow.print()
  }
}

function onDownloadPdf() {
  saveAs(pdfSource.value, pdfFilename.value)
}

function onToggle() {
  emit('toggle')
}

function resetForm() {
  submitted.value = false
  form.pabrik = null
  /*
  form.periode_from = null
  form.periode_to = null
  */
  form.triwulan = null
}
</script>

<template>
  <fx-report-container
    title="LAPORAN TRIWULANAN PER BATCH"
    :loading="isLoadingExport"
    @toggle="onToggle"
  >
    <template #content>
      <form @submit.prevent="search(!v$.$invalid)">
        <div class="formgrid grid">
          <div class="field col-12">
            <div class="font-bold">Filter</div>
          </div>
          <div class="field col-12 md:col-5">
            <AutoComplete
              v-model="form.pabrik"
              :min-length="2"
              :suggestions="list_pabrik"
              field="nama"
              class="w-full"
              input-class="w-full"
              placeholder="Pabrik"
              autofocus
              @complete="searchPabrik($event)"
              :class="{
                'p-invalid': v$.pabrik.$invalid && submitted,
              }"
            >
              <template #item="slotProps">
                <div>
                  <strong
                    >{{ slotProps.item.kode }} -
                    {{ slotProps.item.nama }}</strong
                  >
                </div>
              </template>
            </AutoComplete>
            <small
              v-if="
                (v$.pabrik.$invalid && submitted) ||
                v$.pabrik.$pending.$response
              "
              class="p-error"
              >{{ v$.pabrik.required.$message }}</small
            >
          </div>
          <!--
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.periode_from.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.periode_from.$invalid && submitted,
              }"
              placeholder="Tanggal (dari)"
              class="w-full"
            />
            <small
              v-if="
                (v$.periode_from.$invalid && submitted) ||
                v$.periode_from.$pending.$response
              "
              class="p-error"
              >{{ v$.periode_from.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-2">
            <Calendar
              v-model="v$.periode_to.$model"
              dateFormat="dd/mm/yy"
              :class="{
                'p-invalid': v$.periode_to.$invalid && submitted,
              }"
              placeholder="Tanggal (s/d)"
              class="w-full"
            />
            <small
              v-if="
                (v$.periode_to.$invalid && submitted) ||
                v$.periode_to.$pending.$response
              "
              class="p-error"
              >{{ v$.periode_to.required.$message }}</small
            >
          </div>
          -->
          <div class="field col-12 md:col-3">
            <Dropdown
              v-model="v$.triwulan.$model"
              :options="list_triwulan"
              optionValue="id"
              optionLabel="nama"
              placeholder="Pilih Periode"
              class="w-full"
              :class="{
                'p-invalid': v$.triwulan.$invalid && submitted,
              }"
            />
            <small
              v-if="
                (v$.triwulan.$invalid && submitted) ||
                v$.triwulan.$pending.$response
              "
              class="p-error"
              >{{ v$.triwulan.required.$message }}</small
            >
          </div>
          <div class="field col-12 md:col-3">
            <Button type="submit" icon="pi pi-file-pdf" label="Export" />
            <Button
              type="reset"
              icon="pi pi-refresh"
              label="Reset"
              class="p-button-outlined ml-2"
              @click="resetForm"
            />
          </div>
        </div>
      </form>
    </template>
  </fx-report-container>
  <Dialog
    :header="pdfTitle"
    v-model:visible="dialogViewer"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '90vw' }"
    :modal="true"
  >
    <div>
      <embed
        :src="pdfSource"
        type="application/pdf"
        width="100%"
        height="600px"
      />
    </div>
    <template #footer>
      <Button
        type="button"
        label="Download"
        icon="pi pi-download"
        class="p-button-text mr-2"
        @click="onDownloadPdf"
      />
      <Button
        type="button"
        label="Print"
        icon="pi pi-print"
        @click="onPrintPdf"
      />
    </template>
  </Dialog>
</template>
